var site = site || {};
var generic = generic || {};

site.addToCart = function(args) {
  var skuBaseId;
  if (args.skuData && args.skuData.SKU_BASE_ID) {
    skuBaseId = args.skuData.SKU_BASE_ID;
  } else if (args.skuBaseId) {
    skuBaseId = args.skuBaseId;
  } else {
    return null;
  }

  var quantity;
  if (args.quantity) {
    quantity = args.quantity;
  } else {
    quantity = 1;
  }

  var catBaseId = '';
  if (args.skuData && args.skuData.PARENT_CAT_ID) {
    var matchResult = args.skuData.PARENT_CAT_ID.match("[0-9]+");
    if (matchResult) {
      cat_base_id = matchResult[0];
    }
  }

  args.skus = args.skus || [skuBaseId];
  args.itemType = args.itemType || 'cart';
  args.INCREMENT = 1;
  args.CAT_BASE_ID = args.CAT_BASE_ID || catBaseId;
  args.QTY = args.QTY || quantity;

  generic.checkout.cart.updateCart({
    params: args,
    onSuccess: function(r) {
      // // Report product view based on either loctmpl attribute (if it exists) or last QV location.
      // // Call with URL_CLICK 0 to unset location override. We only neeed it for for add to cart.
      // // This seems kind of horrible to me.
      // if ( typeof Analytics =='object' ){
      //     var locType = $(thisButton).attr("loctmpl");
      //     var params = {};
      //     if (locType) {
      //         location_params = locType.split(",");
      //         params['TYPE_LOCATION'] = location_params[0];
      //         params['PRODUCT_KEY'] = location_params[1];
      //         params['URL_CLICK'] = 0;
      //         Analytics.reportProductView(params);
      //     }
      // }
      var messages = r.getMessages();
      var offerCriteriaMessages = '';
      if (messages) {
          messages.forEach( function(message) {
              if (message.key === "offer_criteria_not_met") {
                  offerCriteriaMessages = offerCriteriaMessages.concat('<br /><br />' +message.text + '<br /><br />');
              }
          });
      }
      if (offerCriteriaMessages) {
          $(document).trigger("addToCart.failure", [messages]);
          generic.overlay.launch({
              content: offerCriteriaMessages,
              includeBackground: true,
              cssStyle: {
                  width: 'auto',
                  height: 'auto',
              }
          });
      }
      else {
          var resultObj = r.getCartResults();
          $(document).trigger("addToCart.success", [resultObj]);
      }
    },
    onFailure: function(ss) {
      var errorObjectsArray = ss.getMessages();
        $(document).trigger("addToCart.failure", [errorObjectsArray]);
        // TODO replace alert message with something nicer
        //alert(prodAddedMsg);
        var resultObj = ss.getCartResults();
        if(errorObjectsArray.length){
            // // Escape any html in the alert box.
            prodAddedMsg = $('<div/>').html(errorObjectsArray[0].text).text();
            generic.overlay.launch({
                content: prodAddedMsg,
                includeBackground: true,
                cssClass: "add_cart_response",
                cssStyle: {
                   width: '300px',
                   height:'auto',
                   padding:'25px'
                }
            });
            resultObj = resultObj || {};
            resultObj.updateCountOnly = 1;
        }
        $(document).trigger("addToCart.success", [resultObj]);
      }
  });

};

site.productData = {
  isActive: function(skuData) {
    return skuData.INVENTORY_STATUS && skuData.INVENTORY_STATUS == 1;
  },
  isTempOutOfStock: function(skuData) {
    return skuData.INVENTORY_STATUS && skuData.INVENTORY_STATUS == 2;
  },
  isComingSoon: function(skuData) {
    return skuData.INVENTORY_STATUS && skuData.INVENTORY_STATUS == 3;
  },
  isInactive: function(skuData) {
    return skuData.INVENTORY_STATUS && skuData.INVENTORY_STATUS == 5;
  },
  isSoldOut: function(skuData) {
    return skuData.INVENTORY_STATUS && skuData.INVENTORY_STATUS == 7;
  },
  isShoppable: function(skuData) {
    return site.productData.isActive(skuData) ||  site.productData.isTempOutOfStock(skuData);
  }
};

site.addToFavorites = function(args) {

  var params = {
    "_SUBMIT": "alter_collection",
    "action": "add"
  };

  var skuBaseId;
  if (args.skuData && args.skuData.SKU_BASE_ID) {
    skuBaseId = args.skuData.SKU_BASE_ID;
  } else if (args.skuBaseId) {
    skuBaseId = args.skuBaseId;
  } else {
    return null;
  }
  params.SKU_BASE_ID = skuBaseId;

  var catBaseId = '';
  if (args.skuData && args.skuData.PARENT_CAT_ID) {
    var matchResult = args.skuData.PARENT_CAT_ID.match("[0-9]+");
    if (matchResult) {
      params.CAT_BASE_ID = matchResult[0];
    }
  }

  var id = generic.jsonrpc.fetch({
    method : 'rpc.form',
    params: [params],
    onSuccess:function(jsonRpcResponse) {
      var d = jsonRpcResponse.getData();
      var r = d.ac_results[0].result;
      
      if (r.KEY == 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
        $(document).trigger("addToWishlist.exists", [r]);
      } else if (r.SUCCESS == 1 || r.KEY == 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
        var cr = jsonRpcResponse.getCartResults();
        $(document).trigger("addToWishlist.success", [cr]);
      }
    },
    onFailure: function(jsonRpcResponse) {
      console.log("add to favorites failure");
      console.log(jsonRpcResponse.getError());
    }
  });
};

/*
 * DEPRECATED - Use createAddButton instead
 */
site.addButton = function(args) {
  var p = args.productData;
  var $addButton = $(".js-add-to-cart[data-product-id=" + p.PRODUCT_ID + "]");
  $addButton.on("click", function(clickEvt) {
    var skuBaseId = $(this).attr("data-sku-base-id");
    var quantity = $(this).attr("data-qty");
    site.addToCart({skuBaseId: skuBaseId, quantity : quantity}  );
  });
  var selectSku = function(skuBaseId) {
    $addButton.attr("data-sku-base-id", skuBaseId);
    updateInvStatus();
  }
  var updateInvStatus = function() {
    var currentSkuId = "SKU" + $addButton.attr("data-sku-base-id");
    var skuDataL2 = $(document).data(currentSkuId);
    if (skuDataL2 && !skuDataL2.isShoppable) {
      $addButton.hide();
    } else {
      $addButton.show();
    }
  }

  selectSku(p.skus[0]["SKU_BASE_ID"]);
  
  $(document).on('sku:select', function(e, skuData) {
    if (skuData.PRODUCT_ID == p.PRODUCT_ID) {
      selectSku(skuData.SKU_BASE_ID);
    }
  });
  $(document).on('inventory_status_stored', function(e, skuData) {
    updateInvStatus();
  });
  var selectQuantity = function(quantity) {
    $addButton.attr("data-qty", quantity);
  }
  $(document).on('qty:select', function(e, quantity) {
    selectQuantity(quantity);
  });
};

site.addFavoritesButton = function($favButton) {
  var that = {};
  that.$favButton = $favButton;

  $favButton.on("click", function(clickEvt) {
    clickEvt.preventDefault();
    var skuBaseId = $(this).attr("data-sku-base-id");
    site.addToFavorites({skuBaseId: skuBaseId});
  });
  var selectSku = function(skuBaseId) {
    $favButton.attr("data-sku-base-id", skuBaseId);
  };
  $favButton.on('sku:select', function(e, skuData) {
    selectSku(skuData.SKU_BASE_ID);
    e.stopPropagation();
  });
};

site.qtySelectMenu = function($selectNode) {
  var that = {
    $selectNode: $selectNode
  };
  that.$selectNode.on("change", function(event) {
    var productId = that.$selectNode.attr("data-product-id");
    var quantity = that.$selectNode.val();
    site.qtySelect(productId, quantity);
  });
  return that;
};

// 
// site.sizeSelectMenu = function($selectNode) {
//   var that = {
//     $selectNode: $selectNode,
//   };
//   that.$selectNode.on("change", function(event) {
//     var selectedSku = $that.selectNode.find('option:selected').attr('data-sku-base-id');
//       var skuData = _.find(that.productData.skus, function(sku){ return sku.SKU_BASE_ID== selectedSku; });
//       site.skuSelect(skuData);
//     });
//     var productId = that.$selectNode.attr("data-product-id");
//     var quantity = that.$selectNode.val();
//     site.qtySelect(productId, quantity);
//   });
//   return that;
// };


site.createAddButton = function($addButton) {
  var that = {};
  that.$addButton = $addButton;
  that.productId = that.$addButton.attr("data-product-id");
  that.$quantity = $('.js-quantity').filter("[data-product-id=" + that.productId +  "]");

  //For new MPP template
  if($('.extended-mpp').length > 0) {
    that.$quantity = $('.js-quantity', that.$addButton.parents("li")).filter("[data-product-id=" + that.productId +  "]");
  }

  // click handler
  that.$addButton.on('click', function(e) {
    e.preventDefault();
    var skuBaseId = $(this).attr("data-sku-base-id");
    if (!skuBaseId || skuBaseId.length<1) return null;
    var args = {skuBaseId: skuBaseId}
    var quantity = $(this).attr("data-qty");
    if (!!quantity) {
      args.quantity = quantity;
    }
    site.addToCart(args);
  });

  // SKU change handler
  var selectSku = function(skuBaseId) {
    that.$addButton.attr("data-sku-base-id", skuBaseId);
    that.updateInvStatus();
  };

  that.$addButton.on('sku:select', function(e, skuData) {
    if (skuData.PRODUCT_ID == that.productId) {
      selectSku(skuData.SKU_BASE_ID);
      that.updateInvStatus();
    }
    e.stopPropagation();
  });

  // Inventory Status change handler
  that.updateInvStatus = function() {
    var currentSkuId = "SKU" + that.$addButton.attr("data-sku-base-id");
    var skuDataL2 = $(document).data(currentSkuId);
    if (skuDataL2 && !skuDataL2.isShoppable) {
      that.$addButton.hide();
      that.$quantity.hide();
    } else {
      that.$addButton.show();
      that.$quantity.show();
    }

    //Show Estimated delivery date in SPP
    if (skuDataL2 && site.EDD && site.EDD.showEstimatedDeliveryDate) {
      site.EDD.showEstimatedDeliveryDate({
        isShoppable: skuDataL2.isShoppable && !(skuDataL2.INVENTORY_STATUS && skuDataL2.INVENTORY_STATUS == 2)
      });
    }
    if (skuDataL2 && site.EDD && site.EDD.whenWillMyOrderArrive) {
      site.EDD.whenWillMyOrderArrive({
        isShoppable: skuDataL2.isShoppable && !(skuDataL2.INVENTORY_STATUS && skuDataL2.INVENTORY_STATUS == 2)
      });
    }
  };

  if (typeof Drupal.settings.globals_variables.hide_quantity_dropdown !== 'undefined' && Drupal.settings.globals_variables.hide_quantity_dropdown) {
    var single_skus = Drupal.settings.globals_variables.hide_quantity_dropdown;
    var key;
    var currentSkuId = parseInt(that.$addButton.attr('data-sku-base-id'));
    that.$quantityMpp = $('.product_brief__quantity-container').filter('[data-product-id=' + that.productId + ']');
    for (key = 0; key < single_skus.length; key++) {
      if (currentSkuId === single_skus[key]) {
        that.$quantityMpp.addClass('hide_qty_dropdown');
        $('.product-full__quantity').addClass('hide_qty_dropdown');
        $('.spp-product__quantity').addClass('hide_qty_dropdown');
      }
    }
  }

  that.$addButton.on('inventory_status_stored', function(e, skuData) {
    that.updateInvStatus();
    e.stopPropagation();
  });

  // Quantity change handler
  var selectQuantity = function(quantity) {
    that.$addButton.attr("data-qty", quantity);
  };
  that.$addButton.on('qty:select', function(e, quantity) {
    selectQuantity(quantity);
    e.stopPropagation();
  });
  return that;
};

// Replenishment
site.replSelect = function(prodId, replAmount) {
  var prodSlctr = "[data-product-id='" + prodId + "']";
  $(prodSlctr).trigger('repl:select', replAmount);
};

site.skuSelect = function(skuData) {
  var prodId = skuData.PRODUCT_ID;
  var prodSlctr = "[data-product-id='" + prodId + "']";
  $(prodSlctr).trigger('sku:select', skuData);
};


site.qtySelect = function(prodId, qty) {
  var prodSlctr = "[data-product-id='" + prodId + "']";
  $(prodSlctr).trigger('qty:select', qty);
};


(function($) {
  Drupal.behaviors.ELB_addToCartButton = {
    attach: function(context, settings) {
      $('.js-add-to-cart').each( function() {
        var btn = site.createAddButton($(this));
      });
    }
  };
  Drupal.behaviors.ELB_addToFavorites = {
    attach: function(context, settings) {
      $('.js-add-to-favorites-btn').each( function() {
		site.addFavoritesButton($(this));
      });
    }
  };
  
})(jQuery);
$(function() {
  var zdc = {};
  zdc.rb = generic.rb('error_messages');
  var zdc_error_response = zdc.rb.get('zdc_out_of_stock');
  $('#addZdcOfferToBag').on('click', function(e) {
    var zdc_redeemed = 0;
    e.preventDefault();
    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [ {
        _SUBMIT: 'offer_code',
        OFFER_CODE: 'zero'
      }],
      onSuccess: function() {
        var containerDiv = jQuery('<div/>', { 'class': 'overlay-content' });
        $('body').append(containerDiv);
        jQuery.ajax('/templates/zero_dollar_checkout_popup.tmpl', {
          method: 'get',
          success: function(transport) {
            containerDiv.append(transport);
            $('p.popup_content_text.zero_dollar_success').addClass('hidden');
            generic.overlay.launch({
              content: containerDiv,
              includeBackground: true,
              cssStyle: {height: '300px'},
              cssClass: ''
            });
          }
        });
      },
      onFailure: function(jsonRpcResponse) {
        var errorObj = jsonRpcResponse.getError();
        var msgObj = jsonRpcResponse.getData();
        var error_messages = '';
        if (msgObj && msgObj.messages) {
          zdc_error_response = zdc.rb.get('zdc_applied');
          $(msgObj.messages).each(function(index, responseMessages) {
            if (responseMessages.key.indexOf('offer_criteria_not_met') !== -1) {
              error_messages += responseMessages.text + '<br/>';
              zdc_redeemed = 1;
            } else {
              error_messages += zdc_error_response + '<br/>';
              return false;
            }
          });
        } else if (errorObj && errorObj.data && errorObj.data.messages) {
          $(errorObj.data.messages).each(function(index, errorMessages) {
            if (errorMessages.key.indexOf('out_of_stock') !== -1) {
              error_messages += zdc_error_response + '<br/>';
              return false;
            } else {
              error_messages += errorMessages.text + '<br/>';
            }
          });
        }
        var containerDiv = jQuery('<div/>', { 'class': 'overlay-content' });
        $('body').append(containerDiv);
        jQuery.ajax('/templates/zero_dollar_checkout_popup.tmpl', {
          method: 'get',
          success: function(transport) {
            containerDiv.append(transport);
            $('.popup_content_text.zero_dollar_already_in_cart').html(error_messages);
            $('.popup_content_text.zero_dollar_success').addClass('hidden');
            if (zdc_redeemed) {
              $('.checkout-buttons').addClass('hidden');
            }
            generic.overlay.launch({
              content: containerDiv,
              includeBackground: true,
              cssStyle: {height: '400px'},
              cssClass: ''
            });
          }
        });
      }
    });
  });
});

$(window).on('load', function () {
  $("body.brand-aerin.device-pc .product_brief__button-panel").removeClass("button--light");
});
